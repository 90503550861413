import {
  PointsOfInterestSearchRequest,
  Station,
  postPriceListGetFuelPricelist,
  postPriceListGetFuelPricelistDiplomat,
  postPriceListGetServicePricelist,
} from "api"
import { GetComponentEdgesQuery } from "apollo"
import { NumberFormatOptions } from "next-intl"
import { extractProperty } from "utils"
import { getNonUmbracoURL } from "utils/utils"
import { GetStockDataResponse } from "./api"

export type ParentPage = {
  url: string
  title: string
  name: string
  isPage: boolean
}
export function getParentsArray(parent: any | undefined, level?: number) {
  const parents: Array<ParentPage> = []
  const num = level || 4
  function recurse(currentParent: any | null) {
    if (currentParent && currentParent.level >= num) {
      parents.push({
        url: getNonUmbracoURL(currentParent.url),
        name: currentParent.name,
        title: extractProperty(currentParent, "pageName") || currentParent.name,
        isPage: !!extractProperty(currentParent, "blocks")?.length || !!extractProperty(currentParent, "pageHeader").length,
      })
      if (currentParent.parent) {
        recurse(currentParent.parent)
      }
    }
  }

  recurse(parent ?? null)
  return parents.reverse() // Reverse the array before returning
}

export async function getPricesList(archive: boolean) {
  const pricesPromise = postPriceListGetFuelPricelist("", {
    IncludeArchive: archive,
  })
  const diplomatsPromise = postPriceListGetFuelPricelistDiplomat("", {
    IncludeArchive: archive,
  })
  const servicesPromise = postPriceListGetServicePricelist("", {
    IncludeArchive: archive,
  })
  const [prices, diplomats, services] = await Promise.all([
    pricesPromise,
    diplomatsPromise,
    servicesPromise,
  ])
  return {
    prices: prices.data.Body,
    diplomats: diplomats.data.Body,
    services: services.data.Body,
  }
}

export type StatsType = {
  marketCap: string | undefined
  peRatio: string | undefined
  dividendYield: string | undefined
  openPrice: string
  maxPrice: string
  minPrice: string
  yearLow: string | undefined
  yearHigh: string | undefined
}

export function getStats(dataObj: GetStockDataResponse) {
  const options = {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }

  const data = dataObj.Body
  const marketCap = data.knowledge_graph.key_stats.stats.find(
    (el) => el.label === "Market cap",
  )?.value
  const peRatio = data.knowledge_graph.key_stats.stats.find(
    (el) => el.label === "P/E ratio",
  )?.value
  const dividendYield = data.knowledge_graph.key_stats.stats.find(
    (el) => el.label === "Dividend yield",
  )?.value
  const openPriceNum = parseFloat(data.graph[0].price)
  const openPrice = new Intl.NumberFormat("en-US", options as NumberFormatOptions).format(openPriceNum)
  const maxPriceNum = data.graph
    .map((el) => parseFloat(el.price))
    .reduce((max, price) => Math.max(max, price), 0)
  const maxPrice = new Intl.NumberFormat("en-US", options as NumberFormatOptions).format(maxPriceNum)
  const minPriceNum = data.graph
    .map((el) => parseFloat(el.price))
    .reduce((min, price) => Math.min(min, price), maxPriceNum)
  const minPrice = new Intl.NumberFormat("en-US", options as NumberFormatOptions).format(minPriceNum)
  const yearRange = data.knowledge_graph.key_stats.stats
    .find((el) => el.label === "Year range")
    ?.value.split(" - ")
    .map((el) => el.replace("ILA ", ""))
  const yearLow = yearRange?.[0]
  const yearHigh = yearRange?.[1]
  return {
    marketCap,
    peRatio,
    dividendYield,
    openPrice,
    maxPrice,
    minPrice,
    yearLow,
    yearHigh,
  }
}

export interface ItemType {
  icon: string
  text: string
  url: string
  description?: string
}

export interface TabType {
  tabLabel: string
  items: ItemType[]
}
export function convertToTabsData(data: any) {
  // Validate the input structure
  if (
    data.__typename !== "BasicBlockListItem" ||
    data.blockType !== "linksLists"
  ) {
    throw new Error("Invalid input data structure.")
  }

  // Extract lists

  const lists = extractProperty(data, "lists")

  // Convert lists into tabsData format
  const tabsData: TabType[] = lists.map((list: any) => {
    const tabLabel = extractProperty(list, "title")
    const items = extractProperty(list, "urls")
    const convertedItems: ItemType[] = items.map((item: any) => {
      const url = extractProperty(item, "link")?.[0]?.url
      const text = extractProperty(item, "linkTitle")
      const iconUrl = extractProperty(item, "linkImage")
      const description = extractProperty(item, "description")
      return {
        icon: iconUrl,
        text: text,
        url,
        description,
      }
    })

    return {
      tabLabel: tabLabel,
      items: convertedItems,
    }
  })

  return tabsData
}

const addPages = [
  // {
  //   url: "",
  //   title: "צרו קשר",
  //   children: [{ url: "/contact", title: "ראשי" }],
  // },
  {
    url: "",
    title: "קריירה בפז",
    children: [{ url: "/jobs", title: "ראשי" }],
  },
  {
    url: "",
    title: "אודות פז",
    children: [
      { url: "/about/about-the-company", title: "ראשי" },
      { url: "/about/corporate-responsibility", title: "אחריות תאגידית בפז" },
    ],
  },
]

// const noMainPage = ["/retail-and-food/", "/energy-and-industries/"]

export type Sitemap = {
  url: string
  title: any
  children: {
    url: string
    title: any
  }[]
}[]

export function getSitemap(site: GetComponentEdgesQuery, withAddedPages: boolean = true) {
  const edges = site.components?.edges || []

  const pages = edges
    .filter((el) => el.node.parent.level === 2 || (el.node.parent.level === 3 && el.node.parent.name === "content"))
    .map((obj) => {
      const node = obj.node as any
      const url = getNonUmbracoURL(node.url)
      const title = node.namedProperties?.pageName?.value || node.name
      const childrenObj = node.children || []
      const children = childrenObj.reduce((acc: any, child: any) => {
        const childUrl = getNonUmbracoURL(child.url)
        const childTitle = child.namedProperties?.pageName?.value || child.name


        if (childUrl.includes("energy-and-industries/pazomat")) {
          acc.push({
            url: "/pazcharge",
            title: "פז Charge",
          })
          acc.push({ url: childUrl, title: childTitle })
          const customObject = {
            url: "/energy-and-industries/pazomat/pazomat-washing",
            title: "אופי רחיצה",
          }
          acc.push(customObject)

        } else {
          acc.push({ url: childUrl, title: childTitle })
        }

        return acc
      }, [])
      const isPage = !!extractProperty(node, "blocks")?.length
      if (!isPage) {

        return { url, title, children }
      }
      children.unshift({
        url: url,
        title: "ראשי",
      })
      return { url, title, children }
    })
    .filter((el) => el.title !== "Blocks example")
  const allPages = withAddedPages ? [...pages, ...addPages] : [...pages]
  return allPages
}

function toRad(Value: number) {
  return (Value * Math.PI) / 180
}

export function calculateDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  meters?: boolean,
) {
  const R = 6371 // Radius of the Earth in km
  const dLat = toRad(lat2 - lat1) // Convert degrees to radians
  const dLon = toRad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
    Math.cos(toRad(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  const distance = R * c // Distance in km
  if (meters) {
    return Math.round(distance * 1000)
  }
  return parseFloat(distance.toFixed(2))
}

export interface ClosetLocation extends Station {
  distance: number
}

export function getClosestPlaces({
  locations,
  lon,
  lat,
  withoutStation
}: {
  locations: Station[]
  lon?: number
  lat?: number,
  withoutStation: boolean
}): ClosetLocation[] {
  if (!lon || !lat) {
    return []
  }
  const closetLocations: ClosetLocation[] = locations
    .map((location) => {
      const distance = calculateDistance(
        lat,
        lon,
        location.geoLocation.latitude,
        location.geoLocation.longitude,
      )
      return {
        ...location,
        distance
      }
    })
  closetLocations.sort((a, b) => a.distance - b.distance)

  const result = withoutStation ?
    closetLocations.filter((el) => {
      const { latitude, longitude } = el.geoLocation
      return latitude !== lat && longitude !== lon
    }) : closetLocations

  return result.slice(0, 10)
}

export const pointsOfInterestSearchRequest: PointsOfInterestSearchRequest = {
  Type: "Pazomat",
  InstallerProvider: null,
  SearchTerm: "",
  PointTypes: [],
  Attributes: [],
  Location: { Latitude: 0, Longitude: 0 },
  Distance: 5000000000 /* double */,
  RequestPage: 0 /* int32 */,
  PageSize: 500 /* int32 */,
  MetaData: []
}

export const checkTwoArrayshaveCommonItems = (arr1: any[], arr2: any[]) => {
  const set1 = new Set(arr1);
  for (const item of arr2) {
    if (set1.has(item)) {
      return true;
    }
  }
  return false;
}
