export interface Attribute {
  id: number
  name: string
  image: string
  imageSelected: string
  category: string
}

export interface LocatorMetadataWrapper {
  Custom: string
  ErrorCode: number /* int32 */
  ErrorMessage: string
  Body: {
    body: Attribute[]
  }
}

export interface Station {
  id: number
  siteId: number
  type: number
  name: string
  address: string
  city: string
  region: string
  product98: boolean
  productGas: boolean
  productUrea: boolean
  isEligibleAppFueling: boolean
  isEligibleAppFuelingInYellow: boolean
  isStoreOrderVoice: boolean
  isStoreOrder: boolean
  isElectric: boolean
  metaData: Attribute[]
  geoRadius: number
  geoLocation: {
    latitude: number
    longitude: number
  }
  distance: number
  subType: string
  phone: string
}

export interface LocatorStationsWrapper {
  Custom: string
  ErrorCode: number /* int32 */
  ErrorMessage: string
  Body: {
    body: {
      stations: Station[]
      geoCity: {
        latitude: number
        longitude: number
      }
    }
  }
}

export interface LocatorCitiesBody {
  stations: Station[]
  cities: { id: number; name: string }[]
}

export interface LocatorCitiesWrapper {
  Custom: string
  ErrorCode: number /* int32 */
  ErrorMessage: string
  Body: {
    body: LocatorCitiesBody
  }
}

export enum AutocompleteType {
  City = "מיקום",
  Station = "תחנה",
}
