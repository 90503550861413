/** THIS FILE IS AUTOMATICALLY GENERATED FROM : https://corporatedataapiqa.paz.co.il/swagger/v1/swagger.json **/

import axios from "../axios"
import * as Paz from "./PazInterfaces"

export const postCacheV1ClearCache = (data: Paz.CacheKeyDto, locale?: string) =>
  axios.post<Paz.BooleanResponseWrapper>(`/api/cache/v1/clearCache`, data, {
    headers: { "X-Accept-Language": locale },
  })

export const postCacheV1ClearAllCache = (locale?: string) =>
  axios.post<Paz.BooleanResponseWrapper>(`/api/cache/v1/clearAllCache`, {
    headers: { "X-Accept-Language": locale },
  })

export const getCitiesAutocomplete = (
  q: string,
  apiVersion: string,
  locale?: string,
) =>
  axios.get<Paz.CityDtoListResponseWrapper>(`/api/cities/autocomplete`, {
    params: { q, "api-version": apiVersion },
    headers: { "X-Accept-Language": locale },
  })

export const postCitiesInternalImportCities = (
  apiVersion: string,
  data: Paz.CitiesRowsRequest,
  locale?: string,
) =>
  axios.post<Paz.BaseCrmIntegrationResponse>(
    `/api/cities/internal/importCities`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postContactUsCreateServiceRequest = (
  apiVersion: string,
  data: Paz.ServiceOrderRequest,
  locale?: string,
) =>
  axios.post<Paz.BooleanResponseWrapper>(
    `/api/contactUs/createServiceRequest`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postContactUsLead = (
  apiVersion: string,
  data: Paz.LeadRequest,
  locale?: string,
) =>
  axios.post<Paz.BooleanResponseWrapper>(`/api/contactUs/lead`, data, {
    params: { "api-version": apiVersion },
    headers: { "X-Accept-Language": locale },
  })

export const postContactUsChargeLeadRequest = (
  apiVersion: string,
  data: Paz.ChargeSaleLeadRequest,
  locale?: string,
) =>
  axios.post<Paz.BooleanResponseWrapper>(
    `/api/contactUs/chargeLeadRequest`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const getDapiStationMetaData = (apiVersion: string, locale?: string) =>
  axios.get<Paz.ObjectResponseWrapper>(`/api/dapi/station/metaData`, {
    params: { "api-version": apiVersion },
    headers: { "X-Accept-Language": locale },
  })

export const getDapiStationGet = (
  stationId: number /* int32 */,
  locationLat: number /* double */,
  locationLng: number /* double */,
  apiVersion: string,
  locale?: string,
) =>
  axios.get<Paz.ObjectResponseWrapper>(`/api/dapi/station/get`, {
    params: { stationId, locationLat, locationLng, "api-version": apiVersion },
    headers: { "X-Accept-Language": locale },
  })

export const postDapiStationMapAutoComplete = (
  apiVersion: string,
  data: Paz.StationTypingAutocompleteRequest,
  locale?: string,
) =>
  axios.post<Paz.ObjectResponseWrapper>(
    `/api/dapi/station/mapAutoComplete`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postDapiStationMapStations = (
  apiVersion: string,
  data: Paz.StationMapRequest,
  locale?: string,
) =>
  axios.post<Paz.ObjectResponseWrapper>(`/api/dapi/station/mapStations`, data, {
    params: { "api-version": apiVersion },
    headers: { "X-Accept-Language": locale },
  })

export const getFinancePazStockModel = (
  range: Paz.StockTimeWindow,
  apiVersion: string,
  locale?: string,
) =>
  axios.get<Paz.FinanceStockModelResponseWrapper>(
    `/api/finance/paz-stock-model`,
    {
      params: { range, "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postJobsUploadCv = (
  apiVersion: string,
  formData: FormData,
  onUploadProgress?: (progressEvent: any) => void,
  locale?: string,
) =>
  axios.post<Paz.BooleanResponseWrapper>(`/api/jobs/uploadCv`, formData, {
    onUploadProgress,
    params: { "api-version": apiVersion },
    headers: {
      "Content-Type": "multipart/form-data",
      "X-Accept-Language": locale,
    },
  })

export const getPazomatInstallStationsAutocomplete = (
  term: string,
  apiVersion: string,
  locale?: string,
) =>
  axios.get<Paz.AutocompleteResponseDtoResponseWrapper>(
    `/api/pazomat-install-stations/autocomplete`,
    {
      params: { term, "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postPazomatInstallStationsGetSearchMetaData = (
  apiVersion: string,
  locale?: string,
) =>
  axios.post<Paz.SearchMetaDataDtoResponseWrapper>(
    `/api/pazomat-install-stations/getSearchMetaData`,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postPazomatInstallStationsSearch = (
  apiVersion: string,
  data: Paz.InstallationStationsSearchRequest,
  locale?: string,
) =>
  axios.post<Paz.InstallationStationDtoListResponseWrapper>(
    `/api/pazomat-install-stations/search`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postPazomatInstallStationsInternalImportStations = (
  apiVersion: string,
  data: Paz.InstServStationRowsRequest,
  locale?: string,
) =>
  axios.post<Paz.BaseCrmIntegrationResponse>(
    `/api/pazomat-install-stations/internal/importStations`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const getPointsOfInterestAutocomplete = (
  term: string,
  apiVersion: string,
  locale?: string,
) =>
  axios.get<Paz.AutocompleteResponseDtoResponseWrapper>(
    `/api/points-of-interest/autocomplete`,
    {
      params: { term, "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postPointsOfInterestSearch = (
  apiVersion: string,
  data: Paz.PointsOfInterestSearchRequest,
  locale?: string,
) =>
  axios.post<Paz.PointsOfInterestDtoPaginatedListResponseWrapper>(
    `/api/points-of-interest/search`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postPointsOfInterestSearchMetaData = (
  apiVersion: string,
  data: Paz.GetSearchMetaDataRequest,
  locale?: string,
) =>
  axios.post<Paz.SearchMetaDataDtoResponseWrapper>(
    `/api/points-of-interest/searchMetaData`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postPointsOfInterestExportSearchResults = (
  apiVersion: string,
  data: Paz.PointsOfInterestSearchRequest,
  locale?: string,
) =>
  axios.post<any>(`/api/points-of-interest/exportSearchResults`, data, {
    params: { "api-version": apiVersion },
    headers: { "X-Accept-Language": locale },
  })

export const postPointsOfInterestInternalImportPoints = (
  apiVersion: string,
  data: Paz.PointsOfInterestRequest,
  locale?: string,
) =>
  axios.post<Paz.BaseCrmIntegrationResponse>(
    `/api/points-of-interest/internal/importPoints`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postPointsOfInterestInternalImportPointsAttributes = (
  apiVersion: string,
  data: Paz.PointsOfInterestAttributesRequest,
  locale?: string,
) =>
  axios.post<Paz.BaseCrmIntegrationResponse>(
    `/api/points-of-interest/internal/importPointsAttributes`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postPriceListGetFuelPricelistDiplomat = (
  apiVersion: string,
  data: Paz.GetPriceListRequest,
  locale?: string,
) =>
  axios.post<Paz.TableDtoResponseWrapper>(
    `/api/priceList/getFuelPricelistDiplomat`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postPriceListGetFuelPricelist = (
  apiVersion: string,
  data: Paz.GetPriceListRequest,
  locale?: string,
) =>
  axios.post<Paz.TableDtoResponseWrapper>(
    `/api/priceList/getFuelPricelist`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postPriceListGetServicePricelist = (
  apiVersion: string,
  data: Paz.GetPriceListRequest,
  locale?: string,
) =>
  axios.post<Paz.TableDtoResponseWrapper>(
    `/api/priceList/getServicePricelist`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postPriceListInternalImportFuelCostsDiplomat = (
  apiVersion: string,
  data: Paz.FuelCostDiplomatRowsRequest,
  locale?: string,
) =>
  axios.post<Paz.BaseCrmIntegrationResponse>(
    `/api/priceList/internal/importFuelCostsDiplomat`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postPriceListInternalImportServicePrices = (
  apiVersion: string,
  data: Paz.ServicePriceRowsRequest,
  locale?: string,
) =>
  axios.post<Paz.BaseCrmIntegrationResponse>(
    `/api/priceList/internal/importServicePrices`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postPriceListInternalImportFuelCosts = (
  apiVersion: string,
  data: Paz.FuelPriceRowsRequest,
  locale?: string,
) =>
  axios.post<Paz.BaseCrmIntegrationResponse>(
    `/api/priceList/internal/importFuelCosts`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postRegionsInternalImportRegions = (
  apiVersion: string,
  data: Paz.RegionsRowsRequest,
  locale?: string,
) =>
  axios.post<Paz.BaseCrmIntegrationResponse>(
    `/api/regions/internal/importRegions`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postTablesInternalImportHeaders = (
  apiVersion: string,
  data: Paz.AttributesHeaderRowsRequest,
  locale?: string,
) =>
  axios.post<Paz.BaseCrmIntegrationResponse>(
    `/api/tables/internal/importHeaders`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postTablesInternalImportValues = (
  apiVersion: string,
  data: Paz.AttributesValuesRowsRequest,
  locale?: string,
) =>
  axios.post<Paz.BaseCrmIntegrationResponse>(
    `/api/tables/internal/importValues`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const getTakriotsAutocomplete = (
  term: string,
  apiVersion: string,
  locale?: string,
) =>
  axios.get<Paz.AutocompleteResponseDtoResponseWrapper>(
    `/api/takriots/autocomplete`,
    {
      params: { term, "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postTakriotsGetSearchMetaData = (
  apiVersion: string,
  locale?: string,
) =>
  axios.post<Paz.SearchMetaDataDtoResponseWrapper>(
    `/api/takriots/getSearchMetaData`,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postTakriotsSearch = (
  apiVersion: string,
  data: Paz.TakariotSearchRequest,
  locale?: string,
) =>
  axios.post<Paz.TakriotDtoListResponseWrapper>(`/api/takriots/search`, data, {
    params: { "api-version": apiVersion },
    headers: { "X-Accept-Language": locale },
  })


export const postTakriotsInternalImportTakriots = (
  apiVersion: string,
  data: Paz.TakriotRowsRequest,
  locale?: string,
) =>
  axios.post<Paz.BaseCrmIntegrationResponse>(
    `/api/takriots/internal/importTakriots`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const getWashStationsSearchMetadata = (
  apiVersion: string,
  locale?: string,
) =>
  axios.get<Paz.SearchMetaDataDtoResponseWrapper>(
    `/api/wash-stations/searchMetadata`,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const getWashStationsAutocomplete = (
  term: string,
  apiVersion: string,
  locale?: string,
) =>
  axios.get<Paz.AutocompleteResponseDtoResponseWrapper>(
    `/api/wash-stations/autocomplete`,
    {
      params: { term, "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postWashStationsSearch = (
  apiVersion: string,
  data: Paz.WashStationSearchRequest,
  locale?: string,
) =>
  axios.post<Paz.WashStationDtoListResponseWrapper>(
    `/api/wash-stations/search`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postWashStationsInternalImportStations = (
  apiVersion: string,
  data: Paz.WashStationRowsRequest,
  locale?: string,
) =>
  axios.post<Paz.BaseCrmIntegrationResponse>(
    `/api/wash-stations/internal/importStations`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const postWashStationsInternalImportTypes = (
  apiVersion: string,
  data: Paz.WashingTypeRowsRequest,
  locale?: string,
) =>
  axios.post<Paz.BaseCrmIntegrationResponse>(
    `/api/wash-stations/internal/importTypes`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const getWashTypesAutoComplete = (
  term: string,
  apiVersion: string,
  locale?: string,
) =>
  axios.get<Paz.CarAutoCompleteResultDtoListResponseWrapper>(
    `/api/wash-types/autoComplete`,
    {
      params: { term, "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )

export const getWashTypesWashTypes = (apiVersion: string, locale?: string) =>
  axios.get<Paz.WashTypeDtoListResponseWrapper>(`/api/wash-types/washTypes`, {
    params: { "api-version": apiVersion },
    headers: { "X-Accept-Language": locale },
  })

export const postWashTypesCarWashTypes = (
  apiVersion: string,
  data: Paz.WashTypePageRequest,
  locale?: string,
) =>
  axios.post<Paz.WashCarRecordDtoPaginatedListResponseWrapper>(
    `/api/wash-types/carWashTypes`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )
