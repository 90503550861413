import axios from "../axios"
import * as Paz from "./PazInterfaces"

export const postPointOfInterstsExportSearchResults = (
  apiVersion: string,
  data: Paz.InstallationStationsSearchRequest,
  locale?: string,
) =>
  axios.post<any>(`/api/points-of-interest/exportSearchResults`, data, { // 1
    params: { "api-version": apiVersion },
    headers: { "X-Accept-Language": locale },
    responseType: "arraybuffer"
  })

export const postPointOfInterstsExportSearchResultsOverride = (
  apiVersion: string,
  data: Paz.InstallationStationsSearchRequest,
  locale?: string,
) =>
  axios.post<any>(`/api/points-of-interest/exportSearchResults`, data, { // 1
    params: { "api-version": apiVersion },
    headers: { "X-Accept-Language": locale },
    responseType: "arraybuffer"
  })

export const postPazomatInstallStationsExportSearchResults = (
  apiVersion: string,
  data: Paz.InstallationStationsSearchRequest,
  locale?: string,
) =>
  axios.post<any>(`/api/pazomat-install-stations/exportSearchResults`, data, { // 1
    params: { "api-version": apiVersion },
    headers: { "X-Accept-Language": locale },
    responseType: "arraybuffer"
  })

export const postTakriotsExportSearchResults = (
  apiVersion: string,
  data: Paz.TakariotSearchRequest,
  locale?: string,
) =>
  axios.post<any>(`/api/takriots/exportSearchResults`, data, { // 5
    params: { "api-version": apiVersion },
    headers: { "X-Accept-Language": locale },
    responseType: "arraybuffer"
  })

export const postWashStationsExportSearchResults = (
  apiVersion: string,
  data: Paz.WashStationSearchRequest,
  locale?: string,
) =>
  axios.post<any>(`/api/wash-stations/exportSearchResults`, data, { // 2
    params: { "api-version": apiVersion },
    headers: { "X-Accept-Language": locale },
    responseType: "arraybuffer"
  })

export const postWashTypesExportSearchResults = (
  apiVersion: string,
  data: Paz.WashTypePageRequest,
  locale?: string,
) =>
  axios.post<any>(`/api/wash-types/exportSearchResults`, data, {
    params: { "api-version": apiVersion },
    headers: { "X-Accept-Language": locale },
    responseType: "arraybuffer"
  })
