import axios from "./axios"
import { WashCarRecordDtoPaginatedListResponseWrapper } from "./generated/PazInterfaces"
interface WashTypePageRequest {
  WashTypeId?: number /* int32 */
  CarModelCode?: string
  RequestPage: number /* int32 */
  PageSize: number /* int32 */
}

export const staticPostWashTypesCarWashTypes = (
  apiVersion: string,
  data: WashTypePageRequest,
  locale?: string,
) =>
  axios.post<WashCarRecordDtoPaginatedListResponseWrapper>(
    `/api/wash-types/carWashTypes`,
    data,
    {
      params: { "api-version": apiVersion },
      headers: { "X-Accept-Language": locale },
    },
  )
